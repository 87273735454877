import React from 'react'
import ScrollToTop from "react-scroll-to-top";
import  Navbar  from  "../inc/Navbar";
import Table from 'react-bootstrap/Table';
import PosterSilworm from './PosterSilkwor';
import PosterSilwormbengali from './PosterSilkwormbengali';
import BrochureLakhya from './BrochureLakhya';
import BrochureTuntchara from './BrochureTuntchara';
import BrochureKisanNursery from './BrochureKisanNursery';
import BrochureEast from './BrochureEast';
import BrochureEgro from './BrochureEgro';
import Brochure1635 from './BrochureS1635';
import Brochure1635Eng from './Brochure1635Eng';
import BrochureS1635tunt from './BrochureS1635tunt';
import BrochureZone9 from './BrochureZone9';
import BrochureEasy from './BrochureEasy';
import BrochureKechua from './BrochureKechua';
import BrochureVermicompost from './BrochureVermicompost';
import BrochureUpai from './BrochureUpai';
import BrochureBiborani from './BrochureBiborani';
import BrochureNidan from './BrochureNidan';
import BrochureForewar from './BrochureForewar';
import BrochureAgaj from './BrochureAgaj';
import BrochurePratikarak from './BrfochurePratikarak';
import BrochurePadhoti from './BrouchrePadhoti';
import brochure21no from "../images/Management_whitefly.pdf";
import brochure23no from "../images/Management_thrips.pdf";
import brochure22no from "../images/tunt_chashe_thrips_pokar_niyontron_poddhyoti.pdf";
import brochure24no from "../images/Tunt_gachhe_Tukra.pdf";
import brochure25no from "../images/Tunt_gachher_sikorer_rog.pdf";
import brocgure26no from "../images/bandhupoka.pdf";
import brochure27no from "../images/kitnashak_ba_chhatraknasak_byaboharer_sabdhanota.pdf";
import brochure28no from "../images/polu_ghar_o_polupaloner_saranjam.pdf";
import brochure29no from "../images/Sericillin_english.pdf";
import brochure30no  from "../images/Sericillin_Bengali.pdf";
import brochure31no  from "../images/Ghar sodhon.pdf";
import b104 from "../images/104 Pamphlet (BHP-DH_English).pdf";
import b103 from "../images/103 Pamphlet (WBDH_Hindi).pdf";
import b102 from "../images/102 Pamphlet (ডব্লিউব্লি–ডীএইচ_Bengali).pdf";
import b101 from "../images/101 Pamphlet (Nistari line_Hindi).pdf";

import b100 from "../images/100 Pamphlet (Nistari line_ Beng).pdf";
import b99 from "../images/99 Pamphlet (12(Y) x BFC1_Hindi).pdf";
import b98 from "../images/98 Pamphlet (১২(ওয়াই) x বিএফবি১_Bengali).pdf";
import b97 from "../images/97 Pamphlet (বিবি ২৫৯_Bengali).pdf";
import b96  from "../images/96 Pamphlet (Wider spacing & Pruning_Bengali).pdf";
import b95 from "../images/95 Pamphlet (BC259_English).pdf";
import b94  from "../images/94 Pamphlet (Wider spacing & Pruning_English).pdf";
import b93 from "../images/93 Pamphlet (Chawki Rearing_Hindi).pdf";
import b92 from "../images/92 Pamphlet (Chawki Rearing_Bengali).pdf";
import b91 from "../images/91 Pamphlet (Chawki Rearing_English).pdf";
import b90 from "../images/90 Pamphlet (12(Y) x BFC1_English).pdf";





function Brochure() {
  return (
    <div>
        <Navbar/>

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <center style={{color:"maroon"}}><h3>PUBLICATION</h3></center>
        <ScrollToTop smooth top="100" color="maroon"/>
        <div className="album py-1">

<div className="container">
    <h5>Brochure / pamphlets</h5>
    </div>
    </div>
        <div className="album py-0">

<div className="container">
        <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Subjcet</th>
          <th>Title of Brochure / pamphletse</th>
          <th>Vernacular</th>
       
          <th>View/Download</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Nutrient management</td>
          <td>	লক্ষ্যমাত্রায় তুঁত পাতা উত্পাদনের জন্য মাটি পরীক্ষা-ভিত্তিক এন-পি-কে সার প্রয়োগের প্রযুক্তি</td>
         
          <td>Bnegali</td>
          <td><BrochureLakhya/></td>
        </tr>
        <tr>
          <td>2</td>
          <td>Mulberry cultivation</td>
          <td>তুঁত চারা তৈরীর পদ্ধতি ও রক্ষণাবেক্ষনের কৌশল</td>
          <td>Bnegali</td>
          <td><BrochureTuntchara/></td>
        </tr>
        <tr>
          <td>3</td>
          <td>Kisan Nursery</td>
          <td>किसान नर्सरीः एक लाभजनक कदम</td>
          <td>Hindi</td>
          <td><BrochureKisanNursery/></td>
        </tr>
        <tr>
          <td>4</td>
          <td>Improved mulberry varieties</td>
          <td>Mulberry varieties suitable for Eastern and North Eastern India</td>
          <td>English</td>
          <td><BrochureEast/></td>
        </tr>
        <tr>
          <td>5</td>
          <td>Improved mulberry varieties</td>
          <td>Mulberry varieties suitable for different agro-climetic zones of West Bengal</td>
          <td>English</td>
          <td><BrochureEgro/></td>
        </tr>
        <tr>
          <td>6</td>
          <td>Improved mulberry varieties</td>
          <td>सिंचित क्षेत्रों के लिए शहतुंत प्रजाति एस-1635  </td>
          <td>Hindi</td>
          <td><Brochure1635/></td>
        </tr>

        <tr>
          <td>7</td>
          <td>Improved mulberry varieties</td>
          <td>Mulberry Variety for Irrigated Zone S-1635     </td>
          <td>English</td>
          <td><Brochure1635Eng/></td>
        </tr>
        <tr>
          <td>8</td>
          <td>Improved mulberry varieties</td>
          <td>তুঁত পাতার অধিক ফলন পেতে এস - 1635	          </td>
          <td>Bengali</td>
          <td><BrochureS1635tunt/></td>
        </tr>
        <tr>
          <td>9</td>
          <td>Improved mulberry varieties</td>
          <td>C-2038: A New Mulberry Variety for Irrigated Zone	          </td>
          <td>English</td>
          <td><BrochureZone9/></td>
        </tr>
        <tr>
          <td>10</td>
          <td>Vermicomposting</td>
          <td>Vermicompost  ……… A made easy</td>
          <td>English</td>
          <td><BrochureEasy/></td>
        </tr>
        <tr>
          <td>11</td>
          <td>Vermicomposting</td>
          <td>केंचुआ – खाद तैयारी करने की आसान पद्धति   </td>
          <td>Hindi</td>
          <td><BrochureKechua/></td>
        </tr>
        <tr>
          <td>12</td>
          <td>Vermicomposting</td>
          <td>রেশম চাষে কেঁচোসার বা ভার্মিকমপোষ্ট</td>
          <td>Bengali</td>
          <td><BrochureVermicompost/></td>
        </tr>


        <tr>
          <td>13</td>
          <td>Mulberry disease management</td>
          <td>তুঁত গাছের নানান রোগ ও প্রতিকারের উপায়</td>
          <td>Bengali</td>
          <td><BrochureUpai/></td>
        </tr>
        <tr>
          <td>14</td>
          <td>Mulberry pest management</td>
          <td>शहतूत पौध में होनेवाले मुख्य रोग एवं कीट का पूर्ब सूचना एवं निदान तथा सावधानी विवरणी।</td>
          <td>Hindi</td>
          <td><BrochureBiborani/></td>
        </tr>
        <tr>
          <td>15</td>
          <td>Mulberry pest management</td>
          <td>शहतूत पौधों में लगने वाले रोग एवं उनका निदान</td>
          <td>Hindi</td>
          <td><BrochureNidan/></td>
        </tr>
        <tr>
          <td>16</td>
          <td>Mulberry pest management</td>
          <td>Forewarning Calendars for the major Pests of Mulberry in West Bengal</td>
          <td>English</td>
          <td><BrochureForewar/></td>
        </tr>
        <tr>
          <td>17</td>
          <td>Mulberry pest management</td>
          <td>शहतूत पौध में लगने वाले पीड़क तथा उनके नियंत्रण के उपाय </td>
          <td>Hindi</td>
          <td><BrochureAgaj/> </td>
        </tr>
        <tr>
          <td>18</td>
          <td>Mulberry pest management</td>
          <td>তুঁত গাছের ক্ষতিকারক পোকা মাকর নিয়ন্ত্রনের গুরুত্বপূর্ণ তথ্য</td>
          <td>Bengali</td>
          <td><BrochureAgaj/> </td>
        </tr>
        <tr>
          <td>19</td>
          <td>Mulberry pest management</td>
          <td>তুঁত গাছের ক্ষতিকারক কীট-পতঙ্গ এবং তাদের প্রতিকার</td>
          <td>Bengali</td>
          <td><BrochurePratikarak/></td>
        </tr>
        <tr>
          <td>20</td>
          <td>Mulberry pest management</td>
          <td>তুঁত চাষে সাদা মাছির নিয়ন্ত্রণ পদ্ধতি</td>
          <td>Bengali</td>
          <td><BrochurePadhoti/></td>
        </tr>





        <tr>
          <td>21</td>
          <td>Mulberry pest management</td>
          <td>Whitefly and its management in Nulberry</td>
          <td>Bengali</td>
        
          <td>  <a className='buttonview' href={brochure21no}>View/Download  </a></td>
         
        </tr>
        <tr>
          <td>22</td>
          <td>Mulberry pest management</td>
          <td> তুঁত চাষে থ্রিপস পোকার নিয়ন্ত্রণ পদ্ধতি</td>
          <td>Bengali</td>
       
          <td><a className='buttonview' href={brochure22no}>View/Download</a></td>
        </tr>
        <tr>
          <td>23</td>
          <td>Mulberry pest management</td>
          <td>Management of Thrips in Mulberry</td>
          <td>English</td>
          <td><a className='buttonview' href={brochure23no}> View/Download</a></td>
        </tr>
        <tr>
          <td>24</td>
          <td>Mulberry pest management</td>
          <td>তুঁত গাছে টুকরা নিয়ন্ত্রণ</td>
          <td>Bengali</td>
         
          <td> <a  className='buttonview' href={brochure24no}>View/Download</a></td>
        </tr>

        <tr>
          <td>25</td>
          <td>Mulberry pest management</td>
          <td>তুঁত গাছের শিকড়ের রোগ, পোকা ও তার প্রতিকার</td>
          <td>Bengali</td>
          <td><a className='buttonview' href={brochure25no}> View/Download</a></td>
        </tr>
        <tr>
          <td>26</td>
          <td>Mulberry pest management</td>
          <td>তুঁত বাগানের উপকারী বন্ধুপোকা</td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={brocgure26no}> View/Download</a></td>
        </tr>
        <tr>
          <td>27</td>
          <td>Protective masseurs</td>
          <td>কীটনাশক বা ছত্রাকনাশক ব্যবহারের সাবধানতা </td>
          <td>Bengali</td>
          <td><a className='buttonview' href={brochure27no}> View/Download</a></td>
        </tr>
        <tr>
          <td>28</td>
          <td>Disinfection of rearing house</td>
          <td>পলু ঘর এবং পলু পালনের সরঞ্জাম শোধনের প্রয়োজনীয়তা / रेशम कीट पालन गृह एवं रेशम कीट पालने के लिए जरूरत सामानो का विसंक्रमण की आवश्वकता  </td>
          <td>Bengali & Hindi</td>
          <td><a className='buttonview' href={brochure28no}> View/Download</a></td>
        </tr>
        <tr>
          <td>29</td>
          <td>Silkworm bed disinfectant</td>
          <td>SERICILLIN – A disinfectant for silkworm body and bed</td>
          <td>English</td>
          <td><a className='buttonview' href={brochure29no}> View/Download</a></td>
        </tr>
        <tr>
          <td>30</td>
          <td>Silkworm bed disinfectant</td>
          <td>সেরিসিলিন – পলু পোকার রোগ প্রতিকারে একটি আদর্শ পাউডার</td>
          <td>Bengali</td>
          <td><a className='buttonview' href={brochure30no}> View/Download</a></td>
        </tr>
        <tr>
          <td>31</td>
          <td>Room disinfectant</td>
          <td>	  ঘর শোধন – পলুঘর ও পলুপালনের আসবাবপত্র শোধনের একটি উত্‍কৃষ্ঠ পাউডার</td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>






        <tr>
          <td>32</td>
          <td>Room disinfectant</td>
          <td>	GHAR SODHON – A fumigant room disinfectant for disinfecting rearing house and its appliances</td>
          <td>English</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>33</td>
          <td>Silkworm DFLs Incubation</td>
          <td>	ডিম মুখানোর সঠিক পদ্ধতি</td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>34</td>
          <td>Silkworm rearing technology</td>
          <td>	  চাকী পলুপালনের নির্দেশিকা</td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>35</td>
          <td>Silkworm rearing technology</td>
          <td>	 বাইভোল্টাইন (জাপানী) চাকী পলুপালনের সঠিক পদ্ধতি</td>
          <td>Bengali</td>
          <td> <a href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>36</td>
          <td>Silkworm rearing technology</td>
          <td>	পলুপালনের সঠিক পদ্ধতি</td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>37</td>
          <td>Silkworm rearing technology</td>
          <td>	  পলু পালনের খুঁটিনাটি</td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>38</td>
          <td>Promising silkworm hybrids</td>
          <td>	আশাপ্রদ নতুন শঙ্কর প্রজাতি – এসকে-৬ x এসকে-৭</td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>39</td>
          <td>Promising silkworm hybrids</td>
          <td>	  New promising Multi x Multivoltine hybrid – Nistari X (SK6 x SK7)</td>
          <td>English</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>40</td>
          <td>Promising silkworm hybrids</td>
          <td>	New promising Multi x Multivoltine hybrid – Nistari X M.Con.4</td>
          <td>English</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>

        <tr>
          <td>41</td>
          <td>Promising silkworm hybrids</td>
          <td>New promising Multi x Multivoltine hybrid – M.Con.1 X M.Con.4</td>
          <td>English</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>

        <tr>
          <td>42</td>
          <td>Promising silkworm hybrids</td>
          <td>	New promising Multi x Bivoltine hybrid – M.Con.1 X B.Con.4</td>
          <td>English</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>43</td>
          <td>Promising silkworm hybrids</td>
          <td>	New promising Multi x Bivoltine hybrid – M6DPC X SK4C</td>
          <td>English</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>44</td>
          <td>Promising silkworm hybrids</td>
          <td>	New promising Bivoltine foundation cross – SK6 X SK7</td>
          <td>English</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>45</td>
          <td>Silkworm disease management</td>
          <td>	রেশম পলু পালনে স্বাস্থ্য বিধান</td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>

        <tr>
          <td>46</td>
          <td>	Silkworm disease managemen</td>
          <td>रेशमकीट के प्रमुख रोग एवं उनका प्रबंधन</td>
          <td>Hindi</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>47</td>
          <td>Silkworm disease managemen</td>
          <td>	रेशम कीटपालन में रोगाणुनाशक का महत्व</td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>48</td>
          <td>Post cocoon technology</td>
          <td>উন্নত মানের রেশম সুতো উত্‍পাদন করার সঠিক পদ্ধতি  </td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>63</td>
          <td>Improved Mulberry Varieties</td>
          <td>	C-2038: A New Mulberry Variety for Irrigated Zone</td>
          <td>English</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>64</td>
          <td>Improved Mulberry Varieties</td>
          <td>	C-2038 सिंचित एवं वर्षाश्रित क्षेत्रों के लिए नई शहतूत उपजाति</td>
          <td>Hindi</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>65</td>
          <td>Improved Mulberry Varieties</td>
          <td>	 সি–২০৩৮ সেচসেবিত এবং বৃষ্টি নির্ভর   অঞ্চলের জন্য নতুন তুঁত প্রজাতি</td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>66</td>
          <td>Drip Fertigation</td>
          <td>	Low Cost Drip Fertigation for Mulberry</td>
          <td>English</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>67</td>
          <td>Drip Fertigation</td>
          <td>	शहतूत के लिए कम लागत वाली ड्रिप फर्टिगेशन प्रणाली</td>
          <td>Hindi</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>68</td>
          <td>Drip Fertigation</td>
          <td>		কম খরচে ফোঁটাফোঁটা করে জল এবং সার প্রয়োগ এর মাধ্যমে তুঁত চাষ</td>
          <td>Bnegali</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>69</td>
          <td>Silkworm Rearing Technology</td>
          <td>	Silkworm Shelf Rearing (Shoot Feeding)</td>
          <td>Hindi</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>

        <tr>
          <td>70</td>
          <td>Silkworm Rearing Technology</td>
          <td>	शेल्फ रेशम कीटपालन (प्ररोह अशन)</td>
          <td>Hindi</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>71</td>
          <td>Silkworm Rearing Technology</td>
          <td>	তাক পদ্ধতিতে পলুপালন</td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>  
           <tr>
          <td>72</td>
          <td>Improved Mulberry Varieties</td>
          <td>		Plastic Collapsible Mountagesfor Quality Cocoon Production</td>
          <td>English</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>

        <tr>
          <td>73</td>
          <td>	
          Silkworm Rearing Technology</td>
          <td>		 गुणवत्ता युक्त कोसा उत्पादन हेतु प्लास्टिक कोलैप्सेबल चंद्रिका</td>
          <td>Hindi</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>74</td>
          <td>Silkworm Rearing Technology</td>
          <td>		ভালো মানের গুটির জন্যে প্লাস্টিক কোলাপসিবল মাউন্টেজ</td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        
        <tr>
          <td>75</td>
          <td>Biocontrol Agent</td>
          <td>		Biological Control Agents : Mulberry Pest Management(Mealybugs & Thrips)</td>
          <td>English</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>

          <td>76</td>
          <td>Biocontrol Agent</td>
          <td>	जैव नियंत्रण एजेंट : शहतूत पीड़क प्रबंधन (चूर्णी मत्कुण एवं थ्रिप्स)</td>
          <td>Hindi</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>



        <tr>
          <td>77</td>
          <td>Biocontrol Agent</td>
          <td> জৈবিক নিয়ন্ত্রণ পদ্ধতিতে তুঁত পাতাকে  ক্ষতিকারক পোকা-মাকড় থেকে রক্ষার উপায় (মিলিবাগ এবং থ্রিপ্স)</td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>78</td>
          <td>Reeling Technology</td>
          <td>	SUVARNA (MOTORIZED CHARKHA) with SOURONEER (SOLAR WATER HEATING UNIT)</td>
          <td>English</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>79</td>
          <td>Reeling Technology</td>
          <td>	सुवर्णा (मोटर चालित चरखा) के साथ सौरोनीर (सौर जल तापन इकाई)</td>
          <td>Hindi</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>80</td>
          <td>Reeling Technology</td>
          <td>	সুভর্ণা (মোটর চালিত চরখা) সাথে সৌরনীড় (সৌর জল গরম করার যন্ত্র)</td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>81</td>
          <td>Silkworm Hybrid</td>
          <td>M6DPC x (SK6.7) New Multi x Bivoltine Hybrid with High Silk Productivity</td>
          <td>English</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>82</td>
          <td>Silkworm Hybrid</td>
          <td>	
          M6DPC x (SK6.7) नई बहुप्रज x द्विप्रज संकर के साथ अधि-रेशम उत्पादकता</td>
          <td>Hindi</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>

        <tr>
          <td>83</td>
          <td>Silkworm Hybrid</td>
          <td>	 উচ্চ রেশম  উত্পাদনকারী নতুন সংকর   প্রজাতি M6DPC x (SK6.7)</td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>84</td>
          <td>Mulberry Pest Mangement</td>
          <td>	তুঁত গাছের শিকড় পচা রোগ নিয়ন্ত্রণে 'রটফিক্স' : একটি পরিবেশ বান্ধব বহুল বিস্তৃত কার্যকরী ঔষধ [কেন্দ্রীয় রেশম উত্‍পাদন অনুসন্ধান ও প্রশিক্ষণ প্রতিষ্ঠান, মহীশুরু হতে উদ্ভাবিত ঔষধ]</td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>85</td>
          <td>Nirmool</td>
          <td>		NIRMOOL- Eco & User friendly disinfectant for Sericulture</td>
          <td>English</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>86</td>
          <td>Nirmool</td>
          <td>	নির্মূল – রেশম্চাষের জন্য পরিবেশ ও ব্যবহারকারী বান্ধব সাধারণ জীবাণুনাশক</td>
          <td>Bengali</td>
          <td> <a  className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>87</td>
          <td>Nirmool</td>
          <td>निर्मूल – रेशमकृषि हेतु पर्यावरण व उपयोगकर्ता अनुकूल एक सामान्य रोगाणुनाशी</td>
          <td>Hindi</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>88</td>
          <td>Nistari Lines</td>
          <td>		Improved Nistari lines (Higher survival & silk productivity)</td>
          <td>English</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>


        

        <tr>
          <td>89</td>
          <td>Silkworm Hybrid</td>
          <td>	WB-DH (WB 1.3 x WB 7.5) Bivoltine Double Hybrid with tolerance high temperature</td>
          <td>English</td>
          <td> <a className='buttonview' href={brochure31no}> View/Download</a></td>
        </tr>
        <tr>
          <td>90</td>
          <td>Silkworm Hybrid</td>
          <td>		12(Y) x BFC1 - Improved crossbreed for Eastern & North Eastern Zone</td>
          <td>English</td>
          <td> <a className='buttonview' href={b90}> View/Download</a></td>
        </tr>

           <tr>
          <td>91</td>
          <td>Chawki Rearing</td>
          <td>	Chawki Rearing for successful silk cocoon crop</td>
          <td>English</td>
          <td> <a className='buttonview' href={b91}> View/Download</a></td>
        </tr>
        <tr>
          <td>92</td>
          <td>Chawki Rearing</td>
          <td>		গুড়া পলুপালন (সফল রেশমচাষের জন্য)</td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={b92}> View/Download</a></td>
        </tr>
        <tr>
          <td>93</td>
          <td>Chawki Rearing</td>
          <td>	चाँकी कीटपालन – सफल रेशम कोकून फसलों के लिए</td>
          <td>Hindi</td>
          <td> <a className='buttonview' href={b93}> View/Download</a></td>
        </tr>
  


        <tr>
          <td>94</td>
          <td>Mulberry Variety</td>
          <td>			Quality mulberry leaf production by wider spacing & proper prunning</td>
          <td>English</td>
          <td> <a className='buttonview' href={b94}> View/Download</a></td>
        </tr>






        <tr>
          <td>95</td>
          <td>Mulberry Variety</td>
          <td>	BC259 - Suitable mulberry variety for hilly regions</td>
          <td>English</td>
          <td> <a className='buttonview' href={b95}> View/Download</a></td>
        </tr>
        <tr>
          <td>96</td>
          <td>Mulberry Variety</td>
          <td>		উচ্চমানের তুঁত পাতা উৎপাদন (প্রশস্ত ব্যবধান এবং যথাযথ ছাঁটাই এর মাধ্যমে)</td>
          <td>Bengali</td>
          <td> <a  className='buttonview' href={b96}> View/Download</a></td>
        </tr>
        <tr>
          <td>97</td>
          <td>Mulberry Variety</td>
          <td>		বিসি২৫৯ – পাহাড়ী অঞ্চলের জন্য উপযুক্ত তুঁত গাছ</td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={b97}> View/Download</a></td>
        </tr>
        <tr>
          <td>98</td>
          <td>Silkworm Hybrid</td>
          <td>		12(Y) x BFC1: पूर्वी तथा उत्तर- पूर्वी क्षेत्रों के लिए उन्नत संकर</td>
          <td>Hindi</td>
          <td> <a className='buttonview' href={b98}> View/Download</a></td>
        </tr>
        <tr>
          <td>99</td>
          <td>Silkworm Hybrid</td>
          <td>12(Y) x BFC1: पूर्वी तथा उत्तर- पूर्वी क्षेत्रों के लिए उन्नत संकर</td>
          <td>Hindi</td>
          <td> <a className='buttonview' href={b99}> View/Download</a></td>
        </tr>


        <tr>
          <td>100</td>
          <td>Silkworm Hybrid</td>
          <td>উন্নতমানের নিস্তারি লাইন - বেঁচে থাকার উচ্চতর শক্তি এবং সিল্কের উৎপাদন শীলতা</td>
          <td>Bengali</td>
          <td> <a  className='buttonview'href={b100}> View/Download</a></td>
        </tr>


        <tr>
          <td>101</td>
          <td>Silkworm Hybrid</td>
          <td>उन्नत निस्तारि लाईन अधिक उत्तरजीविता व रेशम उत्पादकता</td>
          <td>Hindi</td>
          <td> <a className='buttonview' href={b101}> View/Download</a></td>
        </tr>
        <tr>
          <td>102</td>
          <td>Silkworm Hybrid</td>
          <td>ডব্লিউ বি - ডী এইচ: (ডব্লিউ বি ১.৩ X ডব্লিউ বি ৭.৫) উচ্চ তাপমাত্রা সহনশীল বাইভোল্টাইন ডাবল হাইব্রীড</td>
          <td>Bengali</td>
          <td> <a className='buttonview' href={b102}> View/Download</a></td>
        </tr>
        <tr>
          <td>103</td>
          <td>Silkworm Hybrid</td>
          <td>WB-DH (WB 1.3 x WB 7.5): अधिक तापमान के प्रति सहिष्णु द्विप्रज डबल संकर</td>
          <td>Hindi</td>
          <td> <a  className='buttonview' href={b103}> View/Download</a></td>
        </tr>
        <tr>
          <td>104</td>
          <td>Silkworm Hybrid</td>
          <td>BHP-DH: Bivoltine Double Hybrid for Eastern & North -Eastern region</td>
          <td>English</td>
          <td> <a className='buttonview' href={b104}> View/Download</a></td>
        </tr>
      


        
      </tbody>
    </Table>
    </div>
    </div>
    <br/>
    </div>
  )
}

export default Brochure